import React from 'react';

import { Benefit, Subtitle, Item, Icon, Text, Image, List, Root } from './Benefits.styled';

export interface IBenefitsProps {
  className?: string;
}

export const Benefits: React.FC<IBenefitsProps> = ({ className }) => {
  const renderBenefits = ({ icon, text }: IBenefit, index) => (
    <Benefit key={index}>
      <Item>
        <Icon>{icon}</Icon>
        <Text>{text}</Text>
      </Item>
    </Benefit>
  );

  return (
    <Root className={className}>
      <Subtitle>Ihre Vorteile auf einen Blick</Subtitle>
      <List>{benefits.map(renderBenefits)}</List>
    </Root>
  );
};
interface IBenefit {
  icon: React.ReactNode;
  text: string;
}
const benefits: IBenefit[] = [
  {
    icon: <Image src="/img/benefits/tv.png" alt="Digitalisierte Designberatung" />,
    text: 'Digitalisierte Designberatung',
  },
  {
    icon: <Image src="/img/benefits/basket.png" alt="Exklusives Möbelportfolio" />,
    text: 'Exklusives Möbelportfolio',
  },
  {
    icon: <Image src="/img/benefits/reorder.png" alt="Einfache Nachbestellung" />,
    text: 'Einfache Nachbestellung',
  },
  {
    icon: <Image src="/img/benefits/settings.png" alt="inkl. Montage & Verpackungsentsorgung" />,
    text: 'inkl. Montage & Verpackungsentsorgung',
  },
];
