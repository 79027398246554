import React from 'react';

import { Link, Socials, Modal, SubscriptionNews } from '../../../components';

import {
  StyledPageFooter,
  MainSection,
  BottomSection,
  FlexContainer,
  Logo,
  Links,
  RightLinks,
  LinkColumn,
  ButtonNews,
} from './PageFooter.styled';

export const PageFooter: React.FC = () => {
  const [isOpened, setOpenedSubscription] = React.useState(false);

  const handleOpenModalSubscription = () => {
    setOpenedSubscription(true);
  };

  const handleCloseModalSubscription = () => {
    setOpenedSubscription(false);
  };

  return (
    <StyledPageFooter>
      <MainSection>
        <FlexContainer>
          <Link to="/">
            <Logo />
          </Link>
          <Links>
            {/*<LinkColumn>*/}
            {/*  <Link to="/styles">Style Beispiele</Link>*/}
            {/*</LinkColumn>*/}
            <LinkColumn>
              <ButtonNews onClick={handleOpenModalSubscription}>Newsletter Anmeldung</ButtonNews>
            </LinkColumn>
          </Links>
          {/*<Socials {...socials} />*/}
        </FlexContainer>
      </MainSection>
      <BottomSection>
        <FlexContainer>
          <Link to="https://elje-group.com/">© 2020 elje-group</Link>
          <RightLinks>
            <Link to="/terms">AGB</Link>
            <Link to="/imprint">Impressum</Link>
            <Link to="/privacy-policy">Datenschutz</Link>
          </RightLinks>
        </FlexContainer>
      </BottomSection>
      <Modal title="Newsletter Anmeldung" isOpened={isOpened} onClose={handleCloseModalSubscription}>
        <SubscriptionNews />
      </Modal>
    </StyledPageFooter>
  );
};

const socials = {
  ig: 'https://www.instagram.com/furnished/',
  yt: 'https://www.youtube.com/furnished/',
  fb: 'https://www.facebook.com/furnished/',
  tw: 'https://www.twitter.com/furnished/',
};
